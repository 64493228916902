<template>
  <div>
    <Navbar page="Mapeamento de atividades" link="/entregaveis" nameLink="Entregáveis" link_2="/entregaveis/especifico" nameLink_2="Relatórios Específicos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
        <h1 class="text-3xl mb-1">Relatório Específico</h1>
        <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>
        <div class="grid grid-cols-12 gap-6 mt-7">
            <div class="col-span-12 md:col-span-5">
                <label for="tipo_relatorio" class="block text-sm font-medium"> 
                    Escolha o tipo do relatório
                </label>
                <select v-model="form.tipo_relatorio" name="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="grauderisco">Atividades com determinado grau de risco</option>
                    <option value="status">Atividades com determinado status</option>
                    <option value="dadossensiveis">Atividades que contenham dados sensíveis</option>
                    <option value="dadoscriticos">Atividades que contenham dados críticos</option>
                    <option value="responsavel">Atividades realizadas por determinado responsável</option>
                    <option value="setor">Atividade de determinado setor</option>
                    <option value="baselegal">Atividades com determinada base legal</option>
                    <option value="dadopessoal">Atividades que coletem determinado tipo de dado pessoal</option>
                    <option value="formadado">Atividades que tratem dados fisicamente / digital / ambos</option>
                    <option value="tipotitular">Atividades de determinado tipo de titular</option>
                    <option value="titularmenor">Atividades que contenham menores ou vulneráveis</option>
                    <option value="medidasSeguranca">Atividades com determinado tipo de medida de segurança</option>
                    <option value="decisaoAutomatizada">Atividades que contenham decisão automatizada </option>
                    <option value="empresasCompartilhado">Empresa que compartilhamos dados pessoais </option>
                    <option value="empresasEstrangeiraCompartilhada">Empresa estrangeiras que compartilhamos dados pessoais </option>
                    <option value="prazoRetencao">Prazo de retenção por atividades </option>
                    <option value="canaisDeTratamento">Canais de tratamento</option>
                    <option value="agentesDeTratamento">Agentes de tratamento</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'status'" class="col-span-12 md:col-span-5">
                <label for="status" class="block text-sm font-medium">
                    Status da atividade
                </label>
                <select v-model="form.status" name="status" id="status" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option default value="Gerada">Gerada</option>
                  <option default value="Pendente">Pendente</option>
                  <option default value="Respondido">Respondido</option>
                  <option default value="Finalizado">Finalizado</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'grauderisco'" class="col-span-12 md:col-span-5">
                <label for="risco" class="block text-sm font-medium">
                    Risco da atividade
                </label>
                <select v-model="form.grauderisco" name="grauderisco" id="grauderisco" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="muitobaixa">Muito Baixo</option>
                    <option value="baixo">Baixo</option>
                    <option value="medio">Médio</option>
                    <option value="alto">Alto</option>
                    <option value="muitoalto">Muito Alto</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'prazoRetencao'" class="col-span-12 md:col-span-5">
                <label for="tipoPrazoRetencao" class="block text-sm font-medium">
                    Tipo Prazo Retenção
                </label>
                <select required v-model="form.tipoPrazoRetencao" name="status" id="status" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option value="Semanas">Semanas</option>
                  <option value="Dias">Dias</option>
                  <option value="Mês">Mêses</option>
                  <option value="Ano">Anos</option>
                </select>
                <label for="prazoRetencao" class="block text-sm font-medium mt-2">
                    Prazo Retenção ({{ form.tipoPrazoRetencao }})
                </label>
                <input v-model="form.prazoRetencao" type="number" name="prazoRetencao" id="prazoRetencao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
            </div>
            <div v-if="form.tipo_relatorio === 'empresasCompartilhado'" class="col-span-12 md:col-span-5">
                <label for="empresasCompartilhado" class="block text-sm font-medium">
                    Empresa com dados compartilhados
                </label>
                <select v-model="form.empresasCompartilhado" name="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'empresasEstrangeiraCompartilhada'" class="col-span-12 md:col-span-5">
                <label for="empresasEstrangeiraCompartilhada" class="block text-sm font-medium">
                    Empresa com dados compartilhados
                </label>
                <input v-model="form.empresasEstrangeiraCompartilhada" type="text" name="empresasEstrangeiraCompartilhada" id="empresasEstrangeiraCompartilhada" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
            </div>
            <div v-if="form.tipo_relatorio === 'responsavel'" class="col-span-12 md:col-span-5">
                <label for="responsavel" class="block text-sm font-medium">
                    Responsável pela atividade
                </label>
                <select required v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'medidasSeguranca'" class="col-span-12 md:col-span-5">
                <label for="medidasSeguranca" class="block text-sm font-medium">
                    Medida de Segurança
                </label>
                <select required v-model="form.medidasSeguranca" name="medidasSeguranca" id="medidasSeguranca" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="medida in medidasSeguranca" :key="medida._id" :value="medida._id">{{ medida.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'baselegal'" class="col-span-12 md:col-span-5">
                <label for="baselegal" class="block text-sm font-medium">
                    Base Legal utilizada
                </label>
                <select required v-model="form.baselegal" name="baselegal" id="baselegal" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="baselegal in baselegais" :key="baselegal._id" :value="baselegal._id">{{ baselegal.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'setor'" class="col-span-12 md:col-span-5">
                <label for="setor" class="block text-sm font-medium">
                    Setor da atividade
                </label>
                <select required v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'tipotitular'" class="col-span-12 md:col-span-5">
                <label for="tipotitular" class="block text-sm font-medium">
                    Tipo titular da atividade
                </label>
                <select required v-model="form.tipotitular" name="tipotitular" id="tipotitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="tipotitular in tipotitulares" :key="tipotitular._id" :value="tipotitular._id">{{ tipotitular.tipo }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'dadopessoal'" class="col-span-12 md:col-span-5">
                <label for="dados" class="block text-sm font-medium"> 
                    Tipo de dados tratados
                </label>
                <select required v-model="form.dado" name="dados" id="dados" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="dado in dados" :key="dado._id" :value="dado._id">{{ dado.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'formadado'" class="col-span-12 md:col-span-5">
                <label for="formadado" class="block text-sm font-medium"> 
                    Forma de dado
                </label>
                <select v-model="form.formatodadopessoal" name="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="Físico">Físico</option>
                    <option value="Digital">Digital</option>
                    <option value="Ambos">Ambos</option>
                </select>
            </div>
            <div class="col-span-12 md:col-span-2" v-show="form.tipo_relatorio !== 'canaisDeTratamento' && form.tipo_relatorio !== 'agentesDeTratamento'">
                <label class="block text-sm font-medium"> 
                    Buscar atividades
                </label>
                <button @click="buscar()" class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
            </div>
            <div class="col-span-12 md:col-span-2" v-show="form.tipo_relatorio === 'canaisDeTratamento'">
                <label class="block text-sm font-medium"> 
                </label>
                <button @click="gerarRelatorioCanais()" 
                class="mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
                  Download
                </button>
            </div>
            <div class="col-span-12 md:col-span-2" v-show="form.tipo_relatorio === 'agentesDeTratamento'">
                <label class="block text-sm font-medium"> 
                </label>
                <button @click="gerarRelatorioAgentes()" 
                class="mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
                  Download
                </button>
            </div>
            
        </div>

        <div v-if="list && list.length" class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Nome da atividade
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Setor da atividade
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Versão
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="item in list" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.nome ? item.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.setor ? item.setor.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div v-if="item.versao === 'Inicial'" class="text-sm font-semibold text-pendente">
                              {{item.versao ? item.versao : ''}}
                            </div>
                            <div v-else class="text-sm font-semibold text-concluido">
                              {{item.versao ? item.versao : ''}}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
              </div>
            </div>
        </div>

        <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
            <div class="col-span-12 md:col-span-4">
                <label for="titulo" class="block text-sm font-medium">
                    De um título para o relatório
                </label>
                <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                  Gerar Relatório
                </button>
            </div>
            <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
              <label class="block text-sm font-medium">
                  Atividades:
              </label>
              <div class="my-2" v-for="(item, idx) in selecionados" :key="idx">
                <span class="text-sm">{{ item.nome }}</span>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            pessoas: [],
            baselegais: [],
            setores: [],
            tipotitulares: [],
            fornecedores: [],
            medidasSeguranca: [],
            dados: [],
            list: [],
            total: 0,
            page: 1,
            perPage: 20,
            selecionados: [],
            busca: { limit: this.perPage },
            link: "",
            form: {
                tipo_relatorio: null,
                titulo: '',
                formatodadopessoal: '',
                baselegal: null,
                responsavel: null,
                dado: null,
                setor: null,
                tipotitular: null,
                medidasSeguranca: null,
                status: '',
                grauderisco: '',
            }
        }
    },
    methods: {
        async buscar(){
          
            this.busca = { limit: this.perPage };

            if(!this.form.tipo_relatorio) return this.$vToastify.error("Escolha o tipo do relatório");
            
            if(this.form.tipo_relatorio === 'dadossensiveis') this.busca.dadossensiveis = true;

            if(this.form.tipo_relatorio === 'dadoscriticos') this.busca.dadoscriticos = true;

            if(this.form.tipo_relatorio === 'titularmenor') this.busca.titularmenor = true;

            if(this.form.tipo_relatorio === 'decisaoAutomatizada') this.busca.decisaoAutomatizada = true;

            if(this.form.empresasCompartilhado) this.busca.empresasCompartilhado = this.form.empresasCompartilhado;

            if(this.form.empresasEstrangeiraCompartilhada) this.busca.empresasEstrangeiraCompartilhada = this.form.empresasEstrangeiraCompartilhada;

            if(this.form.prazoRetencao) this.busca.prazoRetencao = this.form.prazoRetencao;
            if(this.form.tipoPrazoRetencao) this.busca.tipoPrazoRetencao = this.form.tipoPrazoRetencao;

            if(this.form.tipo_relatorio === 'status') {
                if(!this.form.status) return this.$vToastify.error("Escolha o status");
                this.busca.status = this.form.status;
            }
            if(this.form.tipo_relatorio === 'grauderisco') {
                if(!this.form.grauderisco) return this.$vToastify.error("Escolha o grau de risco");
                this.busca.grauderisco = this.form.grauderisco;
            }

            if(this.form.tipo_relatorio === 'responsavel') {
                if(!this.form.responsavel) return this.$vToastify.error("Escolha o responsável");
                this.busca.responsavel = this.form.responsavel;
            }

            if(this.form.tipo_relatorio === 'medidasSeguranca') {
                if(!this.form.medidasSeguranca) return this.$vToastify.error("Escolha a medida de segurança");
                this.busca.medidasSeguranca = this.form.medidasSeguranca;
            }

            if(this.form.tipo_relatorio === 'baselegal') {
                if(!this.form.baselegal) return this.$vToastify.error("Escolha uma base legal");
                this.busca.baselegal = this.form.baselegal;
            }

            if(this.form.tipo_relatorio === 'setor') {
                if(!this.form.setor) return this.$vToastify.error("Escolha o setor");
                this.busca.setor = this.form.setor;
            }

            if(this.form.tipo_relatorio === 'tipotitular') {
                if(!this.form.tipotitular) return this.$vToastify.error("Escolha o tipo de titular");
                this.busca.tipotitular = this.form.tipotitular;
            }

            if(this.form.tipo_relatorio === 'dadopessoal') {
                if(!this.form.dado) return this.$vToastify.error("Escolha o dado pessoal");
                this.busca.dado = this.form.dado;
            }

            if(this.form.tipo_relatorio === 'formadado') {
                if(!this.form.formatodadopessoal) return this.$vToastify.error("Escolha o formato de dado pessoal");
                this.busca.formatodadopessoal = this.form.formatodadopessoal;
            }

            const listReq = await this.$http.post(`/v1/atividades/list`, this.busca);
            if(!listReq.data || !listReq.data.data.length) {
              this.list = [];
              this.total = 0;
              return this.$vToastify.error("Nenhuma atividade encontrada");
            }
            this.list = listReq.data.data;
            this.total = listReq.data.total;
            this.link = "";
        },

        async setPage(page) {
            this.page = page;
            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            
            this.busca.skip = skip;
            this.busca.limit = limit;
            const listReq = await this.$http.post(`/v1/atividades/list`, this.busca);
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },

        selecionarTodos () {
          const acao = this.selecionados.length > 0 ? 'removerTudo' : 'adicionarTudo';

          if (acao === 'removerTudo') this.selecionados = [];

          if (acao === 'adicionarTudo') {
            for (let i = 0; i < this.list.length; i++) {
              const item = this.list[i];
              this.selecionados.push(item);
            }
          }
        },

        selecionadosControl(item, e) {
          if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
            this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
          } else {
            this.selecionados.push(item);
          }
        },

        async gerarRelatorio(){
          const req = await this.$http.post(`/v1/entregaveis/especifico/atividades`, { titulo: this.form.titulo, tipo_relatorio: this.form.tipo_relatorio, busca: this.busca, selecionados: this.selecionados.map(el => el._id), empresa: this.$store.state.empresa });
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`;
        },
        async gerarRelatorioCanais(){
          const req = await this.$http.post(`/v1/entregaveis/especifico/atividades/canais`, { titulo: this.form.titulo, tipo_relatorio: this.form.tipo_relatorio, busca: this.busca, selecionados: this.selecionados.map(el => el._id), empresa: this.$store.state.empresa });
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`;
        },
        async gerarRelatorioAgentes(){
          const req = await this.$http.post(`/v1/entregaveis/especifico/atividades/agentes`, { titulo: this.form.titulo, tipo_relatorio: this.form.tipo_relatorio, busca: this.busca, selecionados: this.selecionados.map(el => el._id), empresa: this.$store.state.empresa });
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis&original=${req.data.filename}`;
        },
    },
    async beforeMount() {
        const listDados = await this.$http.post(`/v1/dados/list`, { all: true});
        this.dados = listDados.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
        this.fornecedores = listFornecedores.data.data; 

        const listBaselegais = await this.$http.post(`/v1/baselegais/list`, { all: true});
        this.baselegais = listBaselegais.data.data;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listTitulares = await this.$http.post(`/v1/tipotitulares/list`, { all: true});
        this.tipotitulares = listTitulares.data.data;

        const listMedidas = await this.$http.post(`/v1/medidasSeguranca/list`, { all: true});
        this.medidasSeguranca = listMedidas.data.data;
    }
}
</script>